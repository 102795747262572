import React, { useState, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Page from "src/components/Page";
import AuthService from "../../services/auth.service";
import SmstextService from "../../services/smstext.service";
import { loggedInUserState } from "../../services/state";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  chips: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

// eslint-disable-next-line no-unused-vars
const SmstextDetails = ({ ...rest }) => {
  const classes = useStyles();
  const [responseText, setResponseText] = useState("");
  const navigate = useNavigate();
  const { smstextId } = useParams();
  const isNew = smstextId === "new";
  const currentUser = useRecoilValue(loggedInUserState);
  const setLoggedInUser = useSetRecoilState(loggedInUserState);

  useEffect(() => {
    if (!currentUser) {
      navigate("/login", { replace: true });
    }
  }, []);

  const [currentSmstext, setCurrentSmstext] = useState({
    smstext: "",
  });

  useEffect(() => {
    if (currentUser && !isNew) {
      SmstextService.getSmstext(smstextId).then((result) => {
        setCurrentSmstext(result.data);
      });
    }
  }, []);

  return (
    <Page className={classes.root} title="Detail">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Formik
              initialValues={{
                smstext: currentSmstext.smstext,
              }}
              enableReinitialize
              validationSchema={Yup.object().shape({
                smstext: Yup.string()
                  .min(3, "Text je povinný")
                  .max(255, "Max. dĺžka 255 znakov")
                  .required("Text je povinný"),
              })}
              onSubmit={(values, actions) => {
                if (isNew) {
                  SmstextService.createSmstext(values)
                    .then(() => {
                      actions.setSubmitting(false);
                      navigate("/app/smstexts", { replace: true });
                    })
                    .catch((err) => {
                      actions.setSubmitting(false);
                      if (err.response.status === 401) {
                        AuthService.logout();
                        setLoggedInUser(null);
                        navigate("/login", { replace: true });
                      }
                      try {
                        setResponseText(err.response.statusText);
                      } catch {
                        // continue regardless of error
                      }
                    });
                } else {
                  SmstextService.updateSmstext({ id: smstextId, ...values })
                    .then(() => {
                      actions.setSubmitting(false);
                      navigate("/app/smstexts", { replace: true });
                    })
                    .catch((err) => {
                      actions.setSubmitting(false);
                      if (err.response.status === 401) {
                        AuthService.logout();
                        setLoggedInUser(null);
                        navigate("/login", { replace: true });
                      }
                      try {
                        setResponseText(err.response.statusText);
                      } catch {
                        // continue regardless of error
                      }
                    });
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Card>
                    <CardHeader
                      subheader="Posiela sa pri vložení zásielky"
                      title="Text SMS"
                    />
                    <Divider />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField
                            error={Boolean(touched.name && errors.name)}
                            fullWidth
                            label="Text SMS"
                            margin="normal"
                            name="smstext"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.smstext}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <Box display="flex" justifyContent="flex-end" p={2}>
                      <Typography color="error" variant="body1">
                        {responseText}
                      </Typography>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Uložiť
                      </Button>
                    </Box>
                  </Card>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

SmstextDetails.propTypes = {};

export default SmstextDetails;
