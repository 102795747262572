import axios from "axios";
import authHeader from "./auth-header";
import handleDates from "./date-fns";

const { REACT_APP_BACKEND_URL_BASE } = process.env;

const getFutuports = () => {
  return axios.get(`${REACT_APP_BACKEND_URL_BASE}futuports/`, {
    headers: authHeader(),
  });
};

const getFutuport = (futuportId) => {
  return axios.get(`${REACT_APP_BACKEND_URL_BASE}futuport/${futuportId}`, {
    headers: authHeader(),
  });
};

const getFutuportBoxes = (futuportId) => {
  return axios.get(
    `${REACT_APP_BACKEND_URL_BASE}futuport/${futuportId}/boxes/`,
    {
      headers: authHeader(),
    }
  );
};

const getFutuportCompanies = (futuportId) => {
  return axios.get(
    `${REACT_APP_BACKEND_URL_BASE}futuport/${futuportId}/companies/`,
    {
      headers: authHeader(),
    }
  );
};

const getFutuportStatus = (futuportId) => {
  return axios
    .get(`${REACT_APP_BACKEND_URL_BASE}futuport/${futuportId}/status/`, {
      headers: authHeader(),
    })
    .then((response) => {
      handleDates(response.data);
      return response;
    });
};

const getFutuportStatusRequest = (futuportId) => {
  return axios.get(
    `${REACT_APP_BACKEND_URL_BASE}futuport/${futuportId}/request_status/`,
    {
      headers: authHeader(),
    }
  );
};

const getCredentials = (futuportId) => {
  return axios.get(
    `${REACT_APP_BACKEND_URL_BASE}futuport/${futuportId}/credentials/`,
    {
      headers: authHeader(),
    }
  );
};

const createFutuport = (values) => {
  return axios.post(
    `${REACT_APP_BACKEND_URL_BASE}futuport/`,
    {
      name: values.name,
      street_and_nr: values.streetAndNr,
      city: values.city,
      postal_code: values.postalCode,
      country: values.country,
      id_number: values.id_number,
      reservation_days: values.reservation_days,
      smstext_id: values.smstext_id,
      lat: values.lat,
      lng: values.lng,
      opening_hours: values.opening_hours,
    },
    {
      headers: authHeader(),
    }
  );
};

const updateFutuport = (values) => {
  return axios.patch(
    `${REACT_APP_BACKEND_URL_BASE}futuport/`,
    {
      id: values.id,
      name: values.name,
      street_and_nr: values.streetAndNr,
      city: values.city,
      postal_code: values.postalCode,
      country: values.country,
      id_number: values.id_number,
      reservation_days: values.reservation_days,
      smstext_id: values.smstext_id,
      lat: values.lat,
      lng: values.lng,
      opening_hours: values.opening_hours,
    },
    {
      headers: authHeader(),
    }
  );
};

const uploadFutuportImage = (futuportId, file, onUploadProgress) => {
  const formData = new FormData();

  formData.append("image", file);

  return axios.post(
    `${REACT_APP_BACKEND_URL_BASE}futuport/${futuportId}/image/`,
    formData,
    {
      headers: authHeader(),
    },
    onUploadProgress
  );
};

const deleteFutuportImage = (futuportId, imageId) => {
  return axios.delete(
    `${REACT_APP_BACKEND_URL_BASE}futuport/${futuportId}/image/${imageId}`,
    {
      headers: authHeader(),
    }
  );
};

const getFutuportImages = (futuportId) => {
  return axios.get(
    `${REACT_APP_BACKEND_URL_BASE}futuport/${futuportId}/images/`,
    {
      headers: authHeader(),
    }
  );
};

export default {
  getFutuports,
  getFutuport,
  getFutuportBoxes,
  getFutuportCompanies,
  getFutuportStatus,
  getFutuportStatusRequest,
  getCredentials,
  createFutuport,
  updateFutuport,
  uploadFutuportImage,
  deleteFutuportImage,
  getFutuportImages,
};
