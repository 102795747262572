import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const ShippmentDetailsChanges = ({ className, smss, ...rest }) => {
  const classes = useStyles();
  const [selectedSmstextIds] = useState([]);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Odoslané SMS" />
      <Divider />
      <CardContent>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Dátum a čas</TableCell>
                  <TableCell>Tel. č.</TableCell>
                  <TableCell>Text SMS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {smss.map((smsmsg) => (
                  <TableRow
                    hover
                    key={smsmsg.id}
                    selected={selectedSmstextIds.indexOf(smsmsg.id) !== -1}
                  >
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <Typography color="textPrimary" variant="body1">
                          {smsmsg.created.toLocaleString("sk-SK")}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <Typography color="textPrimary" variant="body1">
                          {smsmsg.phone_nr}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <Typography color="textPrimary" variant="body1">
                          {smsmsg.sms_text}
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

ShippmentDetailsChanges.propTypes = {
  className: PropTypes.string,
  smss: PropTypes.array.isRequired,
};

export default ShippmentDetailsChanges;
