import { atom } from "recoil";
import AuthService from "./auth.service";

export const loggedInUserState = atom({
  key: "loggedInUserState",

  default: AuthService.loadCurrentUser(),
});

export default {
  loggedInUserState,
};
