import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { useNavigate, useParams } from "react-router-dom";
// import { w3cwebsocket as W3CWebSocket } from "websocket";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Checkbox,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Page from "src/components/Page";
import FutuportService from "../../services/futuport.service";
import { loggedInUserState } from "../../services/state";
// import handleDates from "../../services/date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

// const { REACT_APP_WS_URL_BASE } = process.env;

// eslint-disable-next-line no-unused-vars
const FutuportDetails = ({ ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { futuportId } = useParams();
  const [statusRecors, setStatusRecords] = useState([]);
  const currentUser = useRecoilValue(loggedInUserState);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!currentUser) {
      navigate("/login", { replace: true });
    }
    // const client = new W3CWebSocket(`${REACT_APP_WS_URL_BASE}${futuportId}`);
    // client.onmessage = (message) => {
    //   const dataFromServer = JSON.parse(message.data);
    //   handleDates(dataFromServer);
    //   setStatusRecords((oldStatusRecors) => [
    //     dataFromServer,
    //     ...oldStatusRecors,
    //   ]);
    //   setIsLoading(false);
    // };
  }, []);

  const [currentFutuport, setCurrentFutuport] = useState({
    id_number: "",
    reservation_days: "",
    name: "",
    street_and_nr: "",
    city: "",
    postal_code: "",
    country: "",
    smstext_id: null,
    lat: "48.3",
    lng: "19.6",
    opening_hours: "Mo-Fr 08:00-12:00,13:00-17:30; Sa 08:00-12:00; PH off",
  });

  useEffect(() => {
    setIsLoading(true);
    FutuportService.getFutuport(futuportId).then((result) => {
      setCurrentFutuport(result.data);
    });
    FutuportService.getFutuportStatus(futuportId).then((result) => {
      // console.log("getFutuportStatus was called");
      setStatusRecords(result.data);
      setIsLoading(false);
    });
  }, []);

  const handleRefreshClick = () => {
    setIsLoading(true);
    // FutuportService.getFutuportStatusRequest(futuportId);
    FutuportService.getFutuportStatus(futuportId).then((result) => {
      setStatusRecords(result.data);
      setIsLoading(false);
    });
  };

  return (
    <Page className={classes.root} title="Status Inboxu">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-start">
              <Typography variant="h3">
                Status {currentFutuport.name}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Box sx={{ display: "flex" }}>
                {isLoading ? <CircularProgress /> : ""}
              </Box>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  handleRefreshClick();
                }}
              >
                Refresh
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Card {...rest}>
              <PerfectScrollbar>
                <Box minWidth={1050}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Čas</TableCell>
                        <TableCell>Bat. status</TableCell>
                        <TableCell>Bat. [V]</TableCell>
                        <TableCell>Bat. [A]</TableCell>
                        <TableCell>Bat. [°C]</TableCell>
                        <TableCell>Board [°C]</TableCell>
                        <TableCell>Board [% hum]</TableCell>
                        <TableCell>ALCC</TableCell>
                        <TableCell>BAT_FLT</TableCell>
                        <TableCell>FUSE_FLT</TableCell>
                        <TableCell>DC12_PG</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {statusRecors.map((rec) => (
                        <TableRow hover key={rec.id}>
                          <TableCell>
                            {rec.created
                              ? rec.created.toLocaleString("sk-SK")
                              : "-"}
                          </TableCell>
                          <TableCell>{rec.dev_status.bat_status}</TableCell>
                          <TableCell>
                            {rec.dev_status.bat_voltage.toFixed(2)}
                          </TableCell>
                          <TableCell>
                            {rec.dev_status.bat_current.toFixed(4)}
                          </TableCell>
                          <TableCell>
                            {rec.dev_status.bat_temperature.toFixed(2)}
                          </TableCell>
                          <TableCell>
                            {rec.dev_status.board_temperature.toFixed(2)}
                          </TableCell>
                          <TableCell>
                            {rec.dev_status.board_humidity.toFixed(2)}
                          </TableCell>
                          <TableCell>
                            <Checkbox
                              checked={rec.dev_status.alcc}
                              value="true"
                            />
                          </TableCell>
                          <TableCell>
                            <Checkbox
                              checked={rec.dev_status.batflt}
                              value="true"
                            />
                          </TableCell>
                          <TableCell>
                            <Checkbox
                              checked={rec.dev_status.fuseflt}
                              value="true"
                            />
                          </TableCell>
                          <TableCell>
                            <Checkbox
                              checked={rec.dev_status.dc12pg}
                              value="true"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </PerfectScrollbar>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

FutuportDetails.propTypes = {};

export default FutuportDetails;
