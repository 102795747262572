import React, { useState, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import Page from "src/components/Page";
import BoxListView from "../box/BoxListView";
import FutuportImages from "./FutuportImages";
import AuthService from "../../services/auth.service";
import FutuportService from "../../services/futuport.service";
import SmstextService from "../../services/smstext.service";
import { loggedInUserState } from "../../services/state";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  chips: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
}));

// eslint-disable-next-line no-unused-vars
const FutuportDetails = ({ ...rest }) => {
  const classes = useStyles();
  const [responseText, setResponseText] = useState("");
  const navigate = useNavigate();
  const { futuportId } = useParams();
  const isNew = futuportId === "new";
  const currentUser = useRecoilValue(loggedInUserState);
  const setLoggedInUser = useSetRecoilState(loggedInUserState);
  const [smstexts, setSmstexts] = useState([]);
  const defaultSmsText =
    "Vaša zásielka {shippment_code} je uložená v boxe {box_boxname} do {storage_timeout}. Kod pre vyber: {addressee_pin}";
  const [dialogContentText, setDialogContentText] = useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [showBoxes, setShowBoxes] = React.useState(false);

  useEffect(() => {
    if (!currentUser) {
      navigate("/login", { replace: true });
    } else {
      SmstextService.getSmstexts().then((result) => {
        setSmstexts(result.data);
      });
    }
  }, []);

  const [currentFutuport, setCurrentFutuport] = useState({
    id_number: "",
    reservation_days: "",
    name: "",
    street_and_nr: "",
    city: "",
    postal_code: "",
    country: "",
    smstext_id: null,
    lat: "48.3",
    lng: "19.6",
    opening_hours: "Mo-Fr 08:00-12:00,13:00-17:30; Sa 08:00-12:00; PH off",
  });

  const [boxes, setBoxes] = useState([]);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    if (!isNew) {
      FutuportService.getFutuport(futuportId).then((result) => {
        setCurrentFutuport(result.data);
      });
      FutuportService.getFutuportBoxes(futuportId).then((result) => {
        setBoxes(result.data);
        if (result.data.length > 0) {
          setShowBoxes(true);
        }
      });
      FutuportService.getFutuportCompanies(futuportId).then((result) => {
        setCompanies(result.data);
      });
    }
  }, []);

  const handleGenerateClick = () => {
    FutuportService.getCredentials(futuportId).then((result) => {
      setOpenDialog(true);
      setDialogContentText(
        `"login": "${result.data.login}",\n"password": "${result.data.password}",`
      );
    });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    FutuportService.getFutuportBoxes(futuportId).then((result) => {
      setBoxes(result.data);
      setShowBoxes(true);
    });
  };

  return (
    <Page className={classes.root} title="Detail Inboxu">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Formik
              initialValues={{
                name: currentFutuport.name,
                streetAndNr: currentFutuport.street_and_nr,
                city: currentFutuport.city,
                postalCode: currentFutuport.postal_code,
                country: currentFutuport.country,
                id_number: currentFutuport.id_number,
                reservation_days: currentFutuport.reservation_days,
                smstext_id: currentFutuport.smstext_id,
                lat: currentFutuport.lat,
                lng: currentFutuport.lng,
                opening_hours: currentFutuport.opening_hours,
              }}
              enableReinitialize
              validationSchema={Yup.object().shape({
                name: Yup.string()
                  .min(3, "Názov je povinný")
                  .max(255)
                  .required("Názov je povinný"),
                streetAndNr: Yup.string()
                  .min(3, "Zadajte ulicu a číslo")
                  .max(255)
                  .required("Zadajte ulicu a číslo"),
                city: Yup.string()
                  .min(3, "Zadajte mesto alebo obec")
                  .max(255)
                  .required("Zadajte mesto alebo obec"),
                postalCode: Yup.string()
                  .min(4, "zadajte platné PSČ")
                  .max(255)
                  .required("Zadajte PSČ"),
                id_number: Yup.number()
                  .min(1, "Zadajte číslo")
                  .max(255)
                  .required("Zadajte číslo"),
                reservation_days: Yup.number()
                  .min(1, "zadajte väčšie ako 1")
                  .max(18, "zadajte menšie ako 18")
                  .required("Zadajte číslo medzi 1 a 18"),
                lat: Yup.number()
                  .min(-90, "zadajte číslo medzi -90.0 a 90.0")
                  .max(90)
                  .required("zadajte číslo medzi -90.0 a 90.0"),
                lng: Yup.number()
                  .min(-180, "zadajte číslo medzi -180.0 a 180.0")
                  .max(180)
                  .required("zadajte číslo medzi -180.0 a 180.0"),
                opening_hours: Yup.string()
                  .min(10, "zadajte aspoň 10 znakov")
                  .max(511, "max 511 znakov")
                  .required("zadajte aspoň 10 znakov"),
              })}
              onSubmit={(values, actions) => {
                if (isNew) {
                  FutuportService.createFutuport(values)
                    .then((result) => {
                      actions.setSubmitting(false);
                      navigate(`/app/futuport/${result.data.id}`, {
                        replace: true,
                      });
                      setShowBoxes(false);
                    })
                    .catch((err) => {
                      actions.setSubmitting(false);
                      console.log(err);
                      if (err.response.status === 401) {
                        AuthService.logout();
                        setLoggedInUser(null);
                      }
                      try {
                        setResponseText(err.response.statusText);
                      } catch {
                        // continue regardless of error
                      }
                    });
                } else {
                  FutuportService.updateFutuport({ id: futuportId, ...values })
                    .then(() => {
                      actions.setSubmitting(false);
                    })
                    .catch((err) => {
                      actions.setSubmitting(false);
                      if (err.response.status === 401) {
                        AuthService.logout();
                        setLoggedInUser(null);
                        navigate("/login", { replace: true });
                      }
                      try {
                        setResponseText(err.response.statusText);
                      } catch {
                        // continue regardless of error
                      }
                    });
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Card>
                    <CardHeader title="Inbox" />
                    <Divider />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.name && errors.name)}
                            fullWidth
                            label="Názov"
                            margin="normal"
                            name="name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.name}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(
                              touched.streetAndNr && errors.streetAndNr
                            )}
                            fullWidth
                            helperText={
                              touched.streetAndNr && errors.streetAndNr
                            }
                            label="Ulica a číslo"
                            margin="normal"
                            name="streetAndNr"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.streetAndNr}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.city && errors.city)}
                            fullWidth
                            helperText={touched.city && errors.city}
                            label="Mesto"
                            margin="normal"
                            name="city"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.city}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(
                              touched.postalCode && errors.postalCode
                            )}
                            fullWidth
                            helperText={touched.postalCode && errors.postalCode}
                            label="PSČ"
                            margin="normal"
                            name="postalCode"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.postalCode}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            error={Boolean(touched.country && errors.country)}
                            fullWidth
                            helperText={touched.country && errors.country}
                            label="Krajina"
                            margin="normal"
                            name="country"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.country}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(
                              touched.id_number && errors.id_number
                            )}
                            fullWidth
                            helperText={touched.id_number && errors.id_number}
                            label="Číslo"
                            margin="normal"
                            name="id_number"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.id_number}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(
                              touched.reservation_days &&
                                errors.reservation_days
                            )}
                            fullWidth
                            helperText={
                              touched.reservation_days &&
                              errors.reservation_days
                            }
                            label="Trvanie rezervácie schránky (dni)"
                            margin="normal"
                            name="reservation_days"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.reservation_days}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.lat && errors.lat)}
                            fullWidth
                            label="Zemepisná šírka"
                            margin="normal"
                            name="lat"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.lat}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.lng && errors.lng)}
                            fullWidth
                            helperText={touched.lng && errors.lng}
                            label="Zamepisná dĺžka"
                            margin="normal"
                            name="lng"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.lng}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            error={Boolean(
                              touched.opening_hours && errors.opening_hours
                            )}
                            fullWidth
                            helperText={
                              touched.opening_hours && errors.opening_hours
                            }
                            label="Dostupnosť v čase"
                            margin="normal"
                            name="opening_hours"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.opening_hours}
                            variant="outlined"
                          />
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                          >
                            ( Napr.: &quot;Mo-Fr 08:00-12:00,13:00-17:30; Sa
                            08:00-12:00; PH off&quot; ){" "}
                            <a
                              href="https://wiki.openstreetmap.org/wiki/Key:opening_hours"
                              taget="blank"
                            >
                              https://wiki.openstreetmap.org/wiki/Key:opening_hours
                            </a>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl className={classes.formControl}>
                            <InputLabel id="simple-select-outlined-label">
                              Priradený text SMS
                            </InputLabel>
                            <Select
                              labelId="simple-select-outlined-label"
                              name="smstext_id"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.smstext_id || ""}
                            >
                              {smstexts.map((smstext) => (
                                <MenuItem key={smstext.id} value={smstext.id}>
                                  {smstext.smstext}
                                </MenuItem>
                              ))}
                            </Select>
                            <Typography
                              variant="caption"
                              display="block"
                              gutterBottom
                            >
                              ( Ak Inbox nemá priradený text SMS, použije sa
                              východzí: {'"'} {defaultSmsText} {'"'} )
                            </Typography>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <Box display="flex" justifyContent="flex-end" p={2}>
                      <Typography color="error" variant="body1">
                        {responseText}
                      </Typography>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Uložiť
                      </Button>
                    </Box>
                  </Card>
                </form>
              )}
            </Formik>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Priradení partneri" />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <div className={classes.chips}>
                    {companies.map((item) => (
                      <Chip
                        p={2}
                        label={item.name}
                        key={item.id}
                        color="default"
                      />
                    ))}
                  </div>
                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Typography color="error" variant="body1">
                  {responseText}
                </Typography>
                <Button
                  color="primary"
                  disabled
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Upraviť
                </Button>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12}>
            {showBoxes ? (
              <BoxListView futuportId={futuportId} boxes={boxes} />
            ) : (
              <Card>
                <CardHeader
                  subheader="Vytvorenie prvotných nastavení"
                  title="Východzia konfigurácia"
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Box display="flex" justifyContent="flex-end" p={2}>
                      <Typography variant="body1">
                        Kliknutím na &quot;GENEROVAŤ VÝCHODZIU
                        KONFIGURÁCIU&quot; vytvoríte prihlasovacie údaje.
                        Starostlivo ich uložte, zobrazia sa len teraz, neskôr
                        ich nie je možné získať.
                      </Typography>
                    </Box>
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      handleGenerateClick();
                    }}
                  >
                    Generovať východziu konfiguráciu
                  </Button>
                  <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      Prihlasovacie údaje
                    </DialogTitle>
                    <DialogContent>
                      <pre>{dialogContentText}</pre>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleCloseDialog}
                        color="primary"
                        autoFocus
                      >
                        Zavrieť
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Box>
              </Card>
            )}
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader subheader="" title="Obrázky" />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <FutuportImages futuportId={futuportId} />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

FutuportDetails.propTypes = {};

export default FutuportDetails;
