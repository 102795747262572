import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { Box, Container, makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import Page from "src/components/Page";
import Results from "./Results";
import Toolbar from "./Toolbar";
import CompanyService from "../../../services/company.service";
import { loggedInUserState } from "../../../services/state";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const CompanyListView = () => {
  const classes = useStyles();
  const [shops, setShops] = useState([]);
  const navigate = useNavigate();
  const currentUser = useRecoilValue(loggedInUserState);

  useEffect(() => {
    if (!currentUser) {
      navigate("/login", { replace: true });
    } else {
      CompanyService.getCompanies().then((result) => {
        setShops(result.data);
      });
    }
  }, []);

  return (
    <Page className={classes.root} title="Spoločnosti">
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Results shops={shops} />
        </Box>
      </Container>
    </Page>
  );
};

export default CompanyListView;
