import React from "react";
import { Navigate } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import MainLayout from "src/layouts/MainLayout";
import AccountView from "src/views/account/AccountView";
import BoxDetails from "src/views/box/BoxDetails";
import CompanyListView from "src/views/company/CompanyListView";
import CompanyDetails from "src/views/company/CompanyDetails";
import FutuportListView from "src/views/futuport/FutuportListView";
import FutuportDetails from "src/views/futuport/FutuportDetails";
import FutuportStatus from "src/views/futuport/FutuportStatus";
import SmstextListView from "src/views/smstext/SmstextListView";
import SmstextDetails from "src/views/smstext/SmstextDetails";
import ShippmentListView from "src/views/shippment/ShippmentListView";
import ShippmentDetails from "src/views/shippment/ShippmentDetails";
import LoginView from "src/views/auth/LoginView";
import LogoutView from "src/views/auth/LogoutView";
import NotFoundView from "src/views/errors/NotFoundView";
import Home from "src/views/home/Home";
import RegisterView from "src/views/auth/RegisterView";
import SettingsView from "src/views/settings/SettingsView";

const routes = [
  {
    path: "app",
    element: <DashboardLayout />,
    children: [
      { path: "account", element: <AccountView /> },
      { path: "companies", element: <CompanyListView /> },
      { path: "futuports", element: <FutuportListView /> },
      { path: "company/:companyId", element: <CompanyDetails /> },
      { path: "futuport/:futuportId", element: <FutuportDetails /> },
      { path: "futuportstatus/:futuportId", element: <FutuportStatus /> },
      { path: "futuport/:futuportId/:boxId", element: <BoxDetails /> },
      { path: "smstexts", element: <SmstextListView /> },
      { path: "smstext/:smstextId", element: <SmstextDetails /> },
      { path: "shippments", element: <ShippmentListView /> },
      { path: "shippment/:shippmentId", element: <ShippmentDetails /> },
      { path: "home", element: <Home /> },
      { path: "settings", element: <SettingsView /> },
      { path: "*", element: <NotFoundView /> },
    ],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "login", element: <LoginView /> },
      { path: "logout", element: <LogoutView /> },
      { path: "register", element: <RegisterView /> },
      { path: "404", element: <NotFoundView /> },
      { path: "/", element: <Navigate to="/app/futuports" /> },
      { path: "*", element: <NotFoundView /> },
    ],
  },
];

export default routes;
