import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="flex-start">
        <Typography variant="h3" className={classes.title}>
          Partneri
        </Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Button
          color="primary"
          variant="contained"
          component={RouterLink}
          to="/app/company/new"
        >
          Pridať partnera
        </Button>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
