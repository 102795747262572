const isoDateFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)(((\+|-)(\d{2}):(\d{2})|Z)?)$/;

const isIsoDateString = (value) => {
  return value && typeof value === "string" && isoDateFormat.test(value);
};

const handleDates = (body) => {
  if (body === null || body === undefined || typeof body !== "object") {
    return body;
  }

  Object.keys(body).forEach((key) => {
    const value = body[key];
    if (isIsoDateString(value)) body[key] = new Date(value);
    else if (typeof value === "object") handleDates(value);
  });

  return body;
};

export default handleDates;
