import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({ className, smstexts, ...rest }) => {
  const classes = useStyles();
  const [selectedSmstextIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Text SMS</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {smstexts
                .slice(limit * page, limit * page + limit)
                .map((smstext) => (
                  <TableRow
                    hover
                    key={smstext.id}
                    selected={selectedSmstextIds.indexOf(smstext.id) !== -1}
                  >
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <Typography color="textPrimary" variant="body1">
                          {smstext.smstext}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          navigate(`/app/smstext/${smstext.id}`, {
                            replace: false,
                          });
                        }}
                      >
                        Upraviť
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={smstexts.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Zobraziť:"
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  smstexts: PropTypes.array.isRequired,
};

export default Results;
