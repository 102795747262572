import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Button, Divider, Grid, Typography } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import FutuportService from "../../services/futuport.service";

const FutuportImages = ({ futuportId }) => {
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");

  const [fileInfos, setFileInfos] = useState([]);

  const selectFile = (event) => {
    setSelectedFiles(event.target.files);
    setMessage("");
  };

  const upload = () => {
    if (selectedFiles === undefined) {
      setMessage("Najprv vyberte obrázok!");
      return;
    }

    const file = selectedFiles[0];

    setProgress(0);
    setCurrentFile(file);

    FutuportService.uploadFutuportImage(futuportId, file, (event) => {
      setProgress(Math.round((100 * event.loaded) / event.total));
    })
      .then((response) => {
        setCurrentFile(undefined);
        setMessage(response.data.message);
        return FutuportService.getFutuportImages(futuportId);
      })
      .then((files) => {
        setFileInfos(files.data);
      })
      .catch(() => {
        setProgress(0);
        setMessage("Could not upload the file!");
        setCurrentFile(undefined);
      });

    setSelectedFiles(undefined);
  };

  const deleteImage = (imageId) => {
    FutuportService.deleteFutuportImage(futuportId, imageId)
      .then(() => {
        return FutuportService.getFutuportImages(futuportId);
      })
      .then((files) => {
        setFileInfos(files.data);
      })
      .catch(() => {
        setMessage("Could not delete file!");
      });
  };

  useEffect(() => {
    if (futuportId !== "new") {
      FutuportService.getFutuportImages(futuportId).then((response) => {
        setFileInfos(response.data);
      });
    }
  }, []);

  return (
    <Grid container spacing={10}>
      {fileInfos &&
        fileInfos.map((file) => (
          <Grid item key={file.id} xs={12}>
            <Grid container spacing={0}>
              <Grid item xs={12} md={6}>
                <Box p={2} display="flex" justifyContent="flex-end">
                  <a href={file.url}>
                    <Typography color="textPrimary" variant="body1">
                      {file.name}
                    </Typography>
                  </a>
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box p={0} display="flex" justifyContent="center">
                  <a href={file.url}>
                    <img src={file.url} alt="" width="160" />
                  </a>
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box p={4} display="flex" justifyContent="center">
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      deleteImage(file.id);
                    }}
                  >
                    Zmazať
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Divider />
          </Grid>
        ))}
      <Grid item xs={12}>
        <Grid container>
          <Box p={2} mx="auto">
            <Grid item xs={12} sm={12}>
              {currentFile && (
                <LinearProgress variant="determinate" value={progress} />
              )}
            </Grid>
            <Grid item xs={12}>
              <Box p={2}>
                <label htmlFor="contained-button-file">
                  <input type="file" onChange={selectFile} />
                </label>
              </Box>
              <Box p={2}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    upload();
                  }}
                >
                  Pridať obrázok
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box p={2}>
                <Typography color="error" variant="body1">
                  {message}
                </Typography>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

FutuportImages.propTypes = {
  futuportId: PropTypes.string.isRequired,
};

export default FutuportImages;
