import axios from "axios";
import authHeader from "./auth-header";

const { REACT_APP_BACKEND_URL_BASE } = process.env;

const getCompanies = () => {
  return axios.get(`${REACT_APP_BACKEND_URL_BASE}company/`, {
    headers: authHeader(),
  });
};

const getCompany = (companyId) => {
  return axios.get(`${REACT_APP_BACKEND_URL_BASE}company/${companyId}`, {
    headers: authHeader(),
  });
};

const getEnabledFutuports = (companyId) => {
  return axios.get(
    `${REACT_APP_BACKEND_URL_BASE}company/${companyId}/futuports/`,
    {
      headers: authHeader(),
    }
  );
};

const setEnabledFutuports = (companyId, futuportId, enabled) => {
  return axios.patch(
    `${REACT_APP_BACKEND_URL_BASE}company/futuports/`,
    {
      company_id: companyId,
      futuport_id: futuportId,
      enabled,
    },
    {
      headers: authHeader(),
    }
  );
};

const getApiKey = (companyId) => {
  return axios.get(
    `${REACT_APP_BACKEND_URL_BASE}company_reset_api_key/${companyId}`,
    {
      headers: authHeader(),
    }
  );
};

const getDocsCredentials = (companyId) => {
  return axios.get(
    `${REACT_APP_BACKEND_URL_BASE}company_reset_docs_credentials/${companyId}`,
    {
      headers: authHeader(),
    }
  );
};

const getAdminCredentials = (companyId) => {
  return axios.get(
    `${REACT_APP_BACKEND_URL_BASE}company_reset_admin_credentials/${companyId}`,
    {
      headers: authHeader(),
    }
  );
};

const createCompany = (values) => {
  return axios.post(
    `${REACT_APP_BACKEND_URL_BASE}company/`,
    {
      name: values.name,
      email: values.email,
      street_and_nr: values.streetAndNr,
      city: values.city,
      postal_code: values.postalCode,
    },
    {
      headers: authHeader(),
    }
  );
};

const updateCompany = (values) => {
  return axios.patch(
    `${REACT_APP_BACKEND_URL_BASE}company/`,
    {
      id: values.id,
      name: values.name,
      email: values.email,
      street_and_nr: values.streetAndNr,
      city: values.city,
      postal_code: values.postalCode,
    },
    {
      headers: authHeader(),
    }
  );
};

export default {
  getApiKey,
  getCompanies,
  getCompany,
  getEnabledFutuports,
  setEnabledFutuports,
  getAdminCredentials,
  getDocsCredentials,
  createCompany,
  updateCompany,
};
