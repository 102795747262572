import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import CompanyService from "../../../services/company.service";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({
  className,
  futuports,
  selectedCompanyId,
  selectedFutuportIds,
  setSelectedFutuportIds,
  ...rest
}) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  // const handleSelectAll = (event) => {
  //   let newSelectedFutuportIds;

  //   if (event.target.checked) {
  //     newSelectedFutuportIds = futuports.map((shop) => shop.id);
  //   } else {
  //     newSelectedFutuportIds = [];
  //   }

  //   setSelectedFutuportIds(newSelectedFutuportIds);
  // };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedFutuportIds.indexOf(id);
    if (selectedCompanyId > 0) {
      CompanyService.setEnabledFutuports(
        selectedCompanyId,
        id,
        event.target.checked
      );
    }
    let newSelectedFutuportIds = [];

    if (selectedIndex === -1) {
      newSelectedFutuportIds = newSelectedFutuportIds.concat(
        selectedFutuportIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedFutuportIds = newSelectedFutuportIds.concat(
        selectedFutuportIds.slice(1)
      );
    } else if (selectedIndex === selectedFutuportIds.length - 1) {
      newSelectedFutuportIds = newSelectedFutuportIds.concat(
        selectedFutuportIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedFutuportIds = newSelectedFutuportIds.concat(
        selectedFutuportIds.slice(0, selectedIndex),
        selectedFutuportIds.slice(selectedIndex + 1)
      );
    }

    setSelectedFutuportIds(newSelectedFutuportIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {selectedCompanyId > 0 ? (
                  <TableCell padding="checkbox">
                    Povolený
                    {/* <Checkbox
                    checked={selectedFutuportIds.length === futuports.length}
                    color="primary"
                    indeterminate={
                      selectedFutuportIds.length > 0 &&
                      selectedFutuportIds.length < futuports.length
                    }
                    onChange={handleSelectAll}
                  /> */}
                  </TableCell>
                ) : (
                  <TableCell padding="checkbox" />
                )}
                <TableCell>Č.</TableCell>
                <TableCell>Názov</TableCell>
                <TableCell>Ulica, číslo</TableCell>
                <TableCell>Mesto</TableCell>
                <TableCell>PSČ</TableCell>
                <TableCell>Krajina</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {futuports
                .slice(limit * page, limit * page + limit)
                .map((shop) => (
                  <TableRow
                    hover
                    key={shop.id}
                    selected={selectedFutuportIds.indexOf(shop.id) !== -1}
                  >
                    {selectedCompanyId > 0 ? (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedFutuportIds.indexOf(shop.id) !== -1}
                          onChange={(event) => handleSelectOne(event, shop.id)}
                          value="true"
                        />
                      </TableCell>
                    ) : (
                      <TableCell padding="checkbox" />
                    )}
                    <TableCell>{shop.id_number}</TableCell>
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <Typography color="textPrimary" variant="body1">
                          {shop.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{shop.street_and_nr}</TableCell>
                    <TableCell>{shop.city}</TableCell>
                    <TableCell>{shop.postal_code}</TableCell>
                    <TableCell>{shop.country}</TableCell>
                    <TableCell>
                      <Box justifyContent="center" display="flex" px={3}>
                        <Box px={3}>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              navigate(`/app/futuport/${shop.id}`, {
                                replace: false,
                              });
                            }}
                          >
                            Zobraziť
                          </Button>
                        </Box>
                        <Button
                          color="primary"
                          variant="contained"
                          size="small"
                          onClick={() => {
                            navigate(`/app/futuportstatus/${shop.id}`, {
                              replace: false,
                            });
                          }}
                        >
                          Status
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={futuports.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Zobraziť:"
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  futuports: PropTypes.array.isRequired,
  selectedCompanyId: PropTypes.number,
  selectedFutuportIds: PropTypes.array,
  setSelectedFutuportIds: PropTypes.func,
};

export default Results;
