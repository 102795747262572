import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    width: "95%",
  },
}));

const Toolbar = ({
  className,
  statusToText,
  inboxNameFilter,
  setInboxNameFilter,
  codeOrTelFilter,
  setCodeOrTelFilter,
  statusFilter,
  setStatusFilter,
  ...rest
}) => {
  const classes = useStyles();
  const handleInboxNameChange = (event) => {
    setInboxNameFilter(event.target.value);
  };
  const handleCodeOrTelChange = (event) => {
    setCodeOrTelFilter(event.target.value);
  };
  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="flex-start">
        <Box>
          <Typography variant="h3" className={classes.title}>
            Zásielky
          </Typography>
        </Box>
      </Box>
      <Grid container>
        <Grid item md={3} xs={12}>
          <Box my={2}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="simple-select-outlined-label">Stav</InputLabel>
              <Select
                id="simple-select-outlined"
                name="partner"
                label="Povolené pre"
                onChange={handleStatusChange}
                value={statusFilter || -1}
              >
                <MenuItem key="vsetky" value="vsetky">
                  Zobraziť všetky
                </MenuItem>
                {Object.keys(statusToText).map((key) => (
                  <MenuItem key={key} value={key}>
                    {statusToText[key]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item md={3} xs={12}>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              fullWidth
              label="Inbox"
              margin="normal"
              name="inbox_name"
              onChange={handleInboxNameChange}
              value={inboxNameFilter}
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item md={3} xs={12}>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              fullWidth
              label="Podacie alebo tel. číslo"
              margin="normal"
              name="code_or_tel"
              onChange={handleCodeOrTelChange}
              value={codeOrTelFilter}
              variant="outlined"
            />
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  inboxNameFilter: PropTypes.string,
  setInboxNameFilter: PropTypes.func,
  codeOrTelFilter: PropTypes.string,
  setCodeOrTelFilter: PropTypes.func,
  statusFilter: PropTypes.string,
  setStatusFilter: PropTypes.func,
  statusToText: PropTypes.object,
};

export default Toolbar;
