import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Page from "src/components/Page";
import AuthService from "../../services/auth.service";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const RegisterView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [responseText, setResponseText] = useState("");
  const phoneRegExp =
    /^(\+421 ?|0) ?9(\d{2}) ?(\d{1}) ?(\d{1}) ?(\d{1}) ?(\d{1}) ?(\d{1}) ?(\d{1})$/;

  return (
    <Page className={classes.root} title="Futuport Admin | Registrácia">
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: "",
              firstName: "",
              lastName: "",
              phone: "",
              streetAndNr: "",
              city: "",
              postalCode: "",
              password: "",
              policy: false,
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Zadajte platný email")
                .max(255)
                .required("Email je povinný údaj"),
              firstName: Yup.string()
                .min(3, "Meno je povinné")
                .max(255)
                .required("Meno je povinné"),
              lastName: Yup.string()
                .min(3, "Priezvisko je povinné")
                .max(255)
                .required("Priezvisko je povinné"),
              phone: Yup.string()
                .matches(phoneRegExp, "Zadajte platné tel. číslo")
                .required("Tel. číslo je povinné"),
              streetAndNr: Yup.string()
                .min(3, "Zadajte ulicu a číslo")
                .max(255)
                .required("Zadajte ulicu a číslo"),
              city: Yup.string()
                .min(3, "Zadajte mesto alebo obec")
                .max(255)
                .required("Zadajte mesto alebo obec"),
              postalCode: Yup.string()
                .min(4, "zadajte platné PSČ")
                .max(255)
                .required("Zadajte PSČ"),
              password: Yup.string().max(255).required("Heslo je povinné"),
              policy: Yup.boolean().oneOf([true], "Toto musí byť zaškrtnuté"),
            })}
            onSubmit={(values, actions) => {
              AuthService.register(values)
                .then(() => {
                  AuthService.login(values.email, values.password).then(() => {
                    AuthService.getUserProfile().then(() => {
                      navigate("/app/scanqr", { replace: true });
                    });
                  });
                })
                .catch((err) => {
                  actions.setSubmitting(false);
                  try {
                    setResponseText(err.response.data.detail);
                  } catch {
                    // continue regardless of error
                  }
                });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">
                    Registrácia
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Vytvorte si osobný účet
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.firstName && errors.firstName)}
                  fullWidth
                  helperText={touched.firstName && errors.firstName}
                  label="Meno"
                  margin="normal"
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.lastName && errors.lastName)}
                  fullWidth
                  helperText={touched.lastName && errors.lastName}
                  label="Priezvisko"
                  margin="normal"
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.streetAndNr && errors.streetAndNr)}
                  fullWidth
                  helperText={touched.streetAndNr && errors.streetAndNr}
                  label="Ulica a číslo"
                  margin="normal"
                  name="streetAndNr"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.streetAndNr}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.city && errors.city)}
                  fullWidth
                  helperText={touched.city && errors.city}
                  label="Mesto"
                  margin="normal"
                  name="city"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.city}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.postalCode && errors.postalCode)}
                  fullWidth
                  helperText={touched.postalCode && errors.postalCode}
                  label="PSČ"
                  margin="normal"
                  name="postalCode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.postalCode}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.phone && errors.phone)}
                  fullWidth
                  helperText={
                    (touched.phone && errors.phone) ||
                    "potrebujeme aby Vás Úrad verejného zdravotníctva mohol v prípade potreby kontaktovať"
                  }
                  label="Mobilné tel. číslo"
                  margin="normal"
                  name="phone"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="tel"
                  value={values.phone}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={
                    (touched.email && errors.email) ||
                    "použite platný email, je potrebný na aktiváciu Vášho prihlásenia"
                  }
                  label="Email"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={
                    (touched.password && errors.password) || "zvoľte si heslo"
                  }
                  label="Heslo"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box alignItems="center" display="flex" ml={-1}>
                  <Checkbox
                    checked={values.policy}
                    name="policy"
                    onChange={handleChange}
                  />
                  <Typography color="textSecondary" variant="body1">
                    Prečítal/a som si a súshlasím s{" "}
                    <Link
                      color="primary"
                      component={RouterLink}
                      to="#"
                      underline="always"
                      variant="h6"
                    >
                      Všeobecnými obchodnými podmienkami
                    </Link>
                  </Typography>
                </Box>
                {Boolean(touched.policy && errors.policy) && (
                  <FormHelperText error>{errors.policy}</FormHelperText>
                )}
                <Typography color="error" variant="body1">
                  {responseText}
                </Typography>
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Zaregistrovať sa
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="body1">
                  Už máte registráciu?{" "}
                  <Link component={RouterLink} to="/login" variant="h6">
                    Prihláste sa.
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default RegisterView;
