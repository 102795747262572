import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import BoxService from "../../services/box.service";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const BoxListView = ({ className, futuportId, boxes, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const handleOpenBoxClick = (boxId) => {
    if (boxId) {
      BoxService.serviceOpenBox(boxId);
    }
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Schránky" />
      <Divider />
      <CardContent>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Č.</TableCell>
                  <TableCell>Š</TableCell>
                  <TableCell>V</TableCell>
                  <TableCell>H</TableCell>
                  <TableCell>TAG</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {boxes.map((shop) => (
                  <TableRow hover key={shop.id}>
                    <TableCell>{shop.id_number}</TableCell>
                    <TableCell>{shop.width}</TableCell>
                    <TableCell>{shop.height}</TableCell>
                    <TableCell>{shop.length}</TableCell>
                    <TableCell>{shop.tag}</TableCell>
                    <TableCell>
                      <Box justifyContent="center" display="flex" px={3}>
                        {/* <Box px={3}>
                          <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                              handleBoxClick(shop.id);
                            }}
                          >
                            Zobraziť zásielku
                          </Button>
                        </Box> */}
                        <Box px={3}>
                          <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                              handleOpenBoxClick(shop.id);
                            }}
                          >
                            ! VZDIALENÉ OTVORENIE !
                          </Button>
                        </Box>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            navigate(`/app/futuport/${futuportId}/${shop.id}`, {
                              replace: false,
                            });
                          }}
                        >
                          Upraviť
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </CardContent>
      <Divider />
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          onClick={() => {
            navigate(`/app/futuport/${futuportId}/new`, { replace: false });
          }}
        >
          Pridať schánku
        </Button>
      </Box>
    </Card>
  );
};

BoxListView.propTypes = {
  className: PropTypes.string,
  boxes: PropTypes.array.isRequired,
  futuportId: PropTypes.string,
};

export default BoxListView;
