import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { Box, Container, makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import Page from "src/components/Page";
import statusToText from "src/services/shippmentStatusToText";
import Results from "./Results";
import Toolbar from "./Toolbar";
import ShippmentService from "../../../services/shippment.service";
import { loggedInUserState } from "../../../services/state";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const ShippmentListView = () => {
  const classes = useStyles();
  const [shippments, setShippments] = useState([]);
  const [inboxNameFilter, setInboxNameFilter] = useState("");
  const [codeOrTelFilter, setCodeOrTelFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("vsetky");
  const navigate = useNavigate();
  const currentUser = useRecoilValue(loggedInUserState);

  useEffect(() => {
    if (!currentUser) {
      navigate("/login", { replace: true });
    } else {
      ShippmentService.getShippments({
        inbox_name: inboxNameFilter,
        code_or_tel: codeOrTelFilter,
        status: statusFilter,
      }).then((result) => {
        setShippments(result);
      });
    }
  }, [inboxNameFilter, codeOrTelFilter, statusFilter]);

  return (
    <Page className={classes.root} title="Zásielky">
      <Container maxWidth={false}>
        <Toolbar
          statusToText={statusToText}
          inboxNameFilter={inboxNameFilter}
          setInboxNameFilter={setInboxNameFilter}
          codeOrTelFilter={codeOrTelFilter}
          setCodeOrTelFilter={setCodeOrTelFilter}
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
        />
        <Box mt={3}>
          <Results
            currentUser={currentUser}
            statusToText={statusToText}
            shippments={shippments}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default ShippmentListView;
