import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
}));

const Toolbar = ({
  className,
  companies,
  selectedCompanyId,
  setSelectedCompanyId,
  ...rest
}) => {
  const classes = useStyles();
  const handleChange = (event) => {
    setSelectedCompanyId(event.target.value);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="flex-start">
        <Typography variant="h3" className={classes.title}>
          Inboxy
        </Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Box px={2}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="simple-select-outlined-label">
              Povolené pre
            </InputLabel>
            <Select
              id="simple-select-outlined"
              name="partner"
              label="Povolené pre"
              onChange={handleChange}
              value={selectedCompanyId || ""}
            >
              <MenuItem key={-1} value={-1}>
                žiadny výber
              </MenuItem>
              {companies.map((company) => (
                <MenuItem key={company.id} value={company.id}>
                  {company.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Button
          color="primary"
          variant="contained"
          component={RouterLink}
          to="/app/futuport/new"
        >
          Pridať Inbox
        </Button>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  companies: PropTypes.array,
  selectedCompanyId: PropTypes.number,
  setSelectedCompanyId: PropTypes.func,
};

export default Toolbar;
