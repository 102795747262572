import axios from "axios";
import authHeader from "./auth-header";

const { REACT_APP_BACKEND_URL_BASE } = process.env;

const getSmstexts = () => {
  return axios.get(`${REACT_APP_BACKEND_URL_BASE}smstext/`, {
    headers: authHeader(),
  });
};

const getSmstext = (textId) => {
  return axios.get(`${REACT_APP_BACKEND_URL_BASE}smstext/${textId}`, {
    headers: authHeader(),
  });
};

const createSmstext = (values) => {
  return axios.post(
    `${REACT_APP_BACKEND_URL_BASE}smstext/`,
    {
      smstext: values.smstext,
    },
    {
      headers: authHeader(),
    }
  );
};

const updateSmstext = (values) => {
  return axios.patch(
    `${REACT_APP_BACKEND_URL_BASE}smstext/`,
    {
      id: values.id,
      smstext: values.smstext,
    },
    {
      headers: authHeader(),
    }
  );
};

export default {
  getSmstexts,
  getSmstext,
  createSmstext,
  updateSmstext,
};
