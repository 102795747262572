import React, { useState, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import AuthService from "../../../services/auth.service";
import { loggedInUserState } from "../../../services/state";

// eslint-disable-next-line no-unused-vars
const ProfileDetails = ({ className, ...rest }) => {
  const [responseText, setResponseText] = useState("");
  const navigate = useNavigate();

  const currentUser = useRecoilValue(loggedInUserState);
  const setLoggedInUser = useSetRecoilState(loggedInUserState);

  useEffect(() => {
    setResponseText("");
    if (!currentUser) {
      navigate("/login", { replace: true });
    }
  }, []);

  return (
    currentUser && (
      <Formik
        initialValues={{
          firstName: currentUser.first_name,
          lastName: currentUser.last_name,
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string()
            .min(3, "Meno je povinné")
            .max(255)
            .required("Meno je povinné"),
          lastName: Yup.string()
            .min(3, "Priezvisko je povinné")
            .max(255)
            .required("Priezvisko je povinné"),
        })}
        onSubmit={(values, actions) => {
          AuthService.updateUserProfile(values)
            .then((response) => {
              actions.setSubmitting(false);
              setLoggedInUser(response.data);
              setResponseText("OK");
              AuthService.getUserProfile();
            })
            .catch((err) => {
              actions.setSubmitting(false);
              if (err.response.status === 401) {
                AuthService.logout();
                setLoggedInUser(null);
                navigate("/login", { replace: true });
              }
              try {
                setResponseText(err.response.data.detail);
              } catch {
                // continue regardless of error
              }
            });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Card>
              <CardHeader subheader="Údaje môžete upraviť" title="Profil" />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.firstName && errors.firstName)}
                      fullWidth
                      helperText={touched.firstName && errors.firstName}
                      label="Meno"
                      margin="normal"
                      name="firstName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.firstName}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.lastName && errors.lastName)}
                      fullWidth
                      helperText={touched.lastName && errors.lastName}
                      label="Priezvisko"
                      margin="normal"
                      name="lastName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.lastName}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-start" p={2}>
                <Box mx={0} style={{ flex: 1 }}>
                  <Button
                    color="secondary"
                    disabled={isSubmitting}
                    size="large"
                    variant="contained"
                    onClick={() => {
                      navigate("/app/settings/", { replace: false });
                    }}
                  >
                    Zmena hesla
                  </Button>
                </Box>
                <Box
                  mx={2}
                  style={{ flex: 2 }}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Box mx={2}>
                    <Typography color="error" variant="body1">
                      {responseText}
                    </Typography>
                  </Box>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Uložiť
                  </Button>
                </Box>
              </Box>
            </Card>
          </form>
        )}
      </Formik>
    )
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string,
};

export default ProfileDetails;
