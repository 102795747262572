import React, { useState, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { DateTimePicker } from "formik-material-ui-pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Page from "src/components/Page";
import statusToText from "src/services/shippmentStatusToText";
import AuthService from "../../services/auth.service";
import ShippmentService from "../../services/shippment.service";
import { loggedInUserState } from "../../services/state";
import ShippmentDetailsChanges from "./ShippmentDetailsChanges";
import ShippmentDetailsSmss from "./ShippmentDetailsSmss";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  chips: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

// eslint-disable-next-line no-unused-vars
const ShippmentDetails = ({ ...rest }) => {
  const classes = useStyles();
  const [responseText, setResponseText] = useState("");
  const [editDisabled, setEditDisabled] = useState(true);
  const navigate = useNavigate();
  const { shippmentId } = useParams();
  const isNew = shippmentId === "new";
  const currentUser = useRecoilValue(loggedInUserState);
  const setLoggedInUser = useSetRecoilState(loggedInUserState);

  useEffect(() => {
    if (!currentUser) {
      navigate("/login", { replace: true });
    }
    if (isNew) {
      navigate("/app/shippments", { replace: true });
    }
  }, []);

  const [currentShippment, setCurrentShippment] = useState({
    box_number: "",
    status: "active",
    inbox_name: "",
    code: "",
    addressee_phone: "",
    storage_time: "",
    storage_timeout: "",
    takeout_time: "",
    returned: "",
  });
  const [shippmentChanges, setShippmentChanges] = useState([]);
  const [smss, setSmss] = useState([]);

  useEffect(() => {
    if (currentUser && !isNew) {
      ShippmentService.getShippment(shippmentId).then((result) => {
        setCurrentShippment(result);
        setEditDisabled(!result.editable);
      });
      ShippmentService.getShippmentChanges(shippmentId).then((result) => {
        setShippmentChanges(result.data);
      });
      ShippmentService.getShippmentSms(shippmentId).then((result) => {
        setSmss(result.data);
      });
    }
  }, []);

  return (
    <Page className={classes.root} title="Detail">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Formik
              initialValues={{
                addressee_phone: currentShippment.addressee_phone,
                storage_timeout: currentShippment.storage_timeout,
              }}
              enableReinitialize
              validationSchema={Yup.object().shape({
                addressee_phone: Yup.string()
                  .min(3, "Text je povinný")
                  .max(255, "Max. dĺžka 255 znakov")
                  .required("Text je povinný"),
                storage_timeout: Yup.string()
                  .min(3, "Dátum je povinný")
                  .max(255, "Max. dĺžka 255 znakov")
                  .required("Dátum je povinný"),
              })}
              onSubmit={(values, actions) => {
                setResponseText("");
                if (isNew) {
                  navigate("/app/shippments", { replace: true });
                } else {
                  ShippmentService.updateShippment({
                    id: shippmentId,
                    ...values,
                  })
                    .then((result) => {
                      actions.setSubmitting(false);
                      setCurrentShippment(result.data);
                      ShippmentService.getShippmentChanges(shippmentId).then(
                        (response) => {
                          setShippmentChanges(response.data);
                        }
                      );
                      ShippmentService.getShippmentSms(shippmentId).then(
                        (response) => {
                          setSmss(response.data);
                        }
                      );
                    })
                    .catch((err) => {
                      actions.setSubmitting(false);
                      if (err.response.status === 401) {
                        AuthService.logout();
                        setLoggedInUser(null);
                        navigate("/login", { replace: true });
                      }
                      try {
                        setResponseText(err.response.data.detail);
                      } catch {
                        try {
                          setResponseText(err.response.statusText);
                          // eslint-disable-next-line no-empty
                        } catch {}
                      }
                    });
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <form onSubmit={handleSubmit}>
                    <Card>
                      <CardHeader
                        subheader="Detail zásielky/rezervácie"
                        title="Zásielka"
                      />
                      <Divider />
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item md={4} xs={12}>
                            <TextField
                              error={Boolean(touched.status && errors.status)}
                              fullWidth
                              label="Stav"
                              margin="normal"
                              name="status"
                              value={statusToText[currentShippment.status]}
                              variant="outlined"
                              disabled
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <TextField
                              error={Boolean(touched.name && errors.name)}
                              fullWidth
                              label="Inbox"
                              margin="normal"
                              name="inbox_name"
                              value={currentShippment.inbox_name}
                              variant="outlined"
                              disabled
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <TextField
                              error={Boolean(touched.name && errors.name)}
                              fullWidth
                              label="Schránka"
                              margin="normal"
                              name="box_number"
                              value={
                                currentShippment.box_number
                                  ? currentShippment.box_number
                                  : ""
                              }
                              variant="outlined"
                              disabled
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <TextField
                              error={Boolean(touched.name && errors.name)}
                              fullWidth
                              label="Vložená"
                              margin="normal"
                              name="storage_time"
                              // prettier-ignore
                              value={currentShippment.storage_time ? currentShippment.storage_time.toLocaleString("sk-SK") : "-"}
                              variant="outlined"
                              disabled
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <TextField
                              error={Boolean(touched.name && errors.name)}
                              fullWidth
                              label="Vyzdvihnutá"
                              margin="normal"
                              name="takeout_time"
                              // prettier-ignore
                              value={currentShippment.takeout_time ? currentShippment.takeout_time.toLocaleString("sk-SK") : "-"}
                              variant="outlined"
                              disabled
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <TextField
                              error={Boolean(touched.code && errors.code)}
                              fullWidth
                              label="Vyzdvihol"
                              margin="normal"
                              name="taken_by"
                              value={
                                // prettier-ignore
                                // eslint-disable-next-line no-nested-ternary
                                !currentShippment.takeout_time ? "-" : currentShippment.returned ? "Kuriér" : "Adresát"
                              }
                              variant="outlined"
                              disabled
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <TextField
                              error={Boolean(touched.name && errors.name)}
                              fullWidth
                              label="Podacie číslo"
                              margin="normal"
                              name="code"
                              value={
                                currentShippment.code
                                  ? currentShippment.code
                                  : ""
                              }
                              variant="outlined"
                              disabled
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <TextField
                              error={Boolean(
                                touched.addressee_phone &&
                                  errors.addressee_phone
                              )}
                              fullWidth
                              label="Tel. číslo"
                              margin="normal"
                              name="addressee_phone"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={
                                values.addressee_phone
                                  ? values.addressee_phone
                                  : ""
                              }
                              variant="outlined"
                              disabled={editDisabled}
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <Box my={2}>
                              <Field
                                component={DateTimePicker}
                                error={Boolean(
                                  touched.storage_timeout &&
                                    errors.storage_timeout
                                )}
                                name="storage_timeout"
                                label="Lehota"
                                value={values.storage_timeout}
                                format="dd.MM.yyy, HH:mm:ss"
                                inputVariant="outlined"
                                ampm={false}
                                disabled={editDisabled}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                      <Divider />
                      <Box display="flex" justifyContent="flex-end" p={2}>
                        <Box px={2}>
                          <Typography color="error" variant="body1">
                            {responseText}
                          </Typography>
                        </Box>
                        <Box px={2}>
                          <Button
                            color="primary"
                            disabled={editDisabled || isSubmitting}
                            size="large"
                            type="submit"
                            variant="contained"
                          >
                            Uložiť
                          </Button>
                        </Box>
                      </Box>
                    </Card>
                  </form>
                </MuiPickersUtilsProvider>
              )}
            </Formik>
          </Grid>
          <Grid item xs={12}>
            <ShippmentDetailsSmss smss={smss} />
          </Grid>
          <Grid item xs={12}>
            <ShippmentDetailsChanges shippmentChanges={shippmentChanges} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

ShippmentDetails.propTypes = {};

export default ShippmentDetails;
