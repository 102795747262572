import axios from "axios";
import authHeader from "./auth-header";

const { REACT_APP_BACKEND_URL_BASE } = process.env;

const getBox = (futuportId, boxId) => {
  return axios.get(`${REACT_APP_BACKEND_URL_BASE}box/${boxId}`, {
    headers: authHeader(),
  });
};

const serviceOpenBox = (boxId) => {
  return axios.get(`${REACT_APP_BACKEND_URL_BASE}box/${boxId}/service_open`, {
    headers: authHeader(),
  });
};

const createBox = (futuportId, values) => {
  return axios.post(
    `${REACT_APP_BACKEND_URL_BASE}box/`,
    {
      futuport_id: futuportId,
      id_number: values.id_number,
      length: values.length,
      width: values.width,
      height: values.height,
      tag: values.tag,
    },
    {
      headers: authHeader(),
    }
  );
};

const updateBox = (values) => {
  return axios.patch(
    `${REACT_APP_BACKEND_URL_BASE}box/`,
    {
      id: values.boxId,
      futuport_id: values.futuportId,
      id_number: values.id_number,
      length: values.length,
      width: values.width,
      height: values.height,
      tag: values.tag,
    },
    {
      headers: authHeader(),
    }
  );
};

export default {
  getBox,
  serviceOpenBox,
  createBox,
  updateBox,
};
