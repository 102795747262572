import React, { useState, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Page from "src/components/Page";
import AuthService from "../../services/auth.service";
import BoxService from "../../services/box.service";
import { loggedInUserState } from "../../services/state";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
}));

// eslint-disable-next-line no-unused-vars
const BoxDetails = ({ ...rest }) => {
  const classes = useStyles();
  const [responseText, setResponseText] = useState("");
  const navigate = useNavigate();
  const { futuportId, boxId } = useParams();
  const isNew = boxId === "new";
  const currentUser = useRecoilValue(loggedInUserState);
  const setLoggedInUser = useSetRecoilState(loggedInUserState);

  useEffect(() => {
    if (!currentUser) {
      navigate("/login", { replace: true });
    }
  }, []);

  const [currentBox, setCurrentBox] = useState({
    id_number: "",
    width: "",
    height: "",
    length: "",
    tag: "L",
  });

  useEffect(() => {
    if (!isNew) {
      BoxService.getBox(futuportId, boxId).then((result) => {
        setCurrentBox(result.data);
      });
    }
  }, []);

  return (
    <Page className={classes.root} title="Detail schránky">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Formik
              initialValues={{
                id_number: currentBox.id_number,
                width: currentBox.width,
                height: currentBox.height,
                length: currentBox.length,
                tag: currentBox.tag,
              }}
              enableReinitialize
              validationSchema={Yup.object().shape({
                id_number: Yup.number()
                  .min(1, "Zadajte číslo")
                  .max(255)
                  .required("Zadajte číslo"),
                width: Yup.number()
                  .min(1, "zadajte väčšie ako 1")
                  .max(100, "zadajte menšie ako 100")
                  .required("Zadajte číslo medzi 1 a 100"),
                height: Yup.number()
                  .min(1, "zadajte väčšie ako 1")
                  .max(100, "zadajte menšie ako 100")
                  .required("Zadajte číslo medzi 1 a 100"),
                length: Yup.number()
                  .min(1, "zadajte väčšie ako 1")
                  .max(100, "zadajte menšie ako 100")
                  .required("Zadajte číslo medzi 1 a 100"),
                tag: Yup.string()
                  .min(1, "Tag je povinný")
                  .max(3)
                  .required("Tag je povinný"),
              })}
              onSubmit={(values, actions) => {
                if (isNew) {
                  BoxService.createBox(futuportId, values)
                    .then(() => {
                      actions.setSubmitting(false);
                      navigate(`/app/futuport/${futuportId}`, {
                        replace: true,
                      });
                    })
                    .catch((err) => {
                      actions.setSubmitting(false);
                      console.log(err);
                      if (err.response.status === 401) {
                        AuthService.logout();
                        setLoggedInUser(null);
                      }
                      try {
                        setResponseText(err.response.statusText);
                      } catch {
                        // continue regardless of error
                      }
                    });
                } else {
                  BoxService.updateBox({
                    futuportId,
                    boxId,
                    ...values,
                  })
                    .then(() => {
                      actions.setSubmitting(false);
                      navigate(`/app/futuport/${futuportId}`, {
                        replace: true,
                      });
                    })
                    .catch((err) => {
                      actions.setSubmitting(false);
                      if (err.response.status === 401) {
                        AuthService.logout();
                        setLoggedInUser(null);
                        navigate("/login", { replace: true });
                      }
                      try {
                        setResponseText(err.response.statusText);
                      } catch {
                        // continue regardless of error
                      }
                    });
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Card>
                    <CardHeader title="Schránka" />
                    <Divider />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(
                              touched.id_number && errors.id_number
                            )}
                            fullWidth
                            helperText={touched.id_number && errors.id_number}
                            label="Číslo"
                            margin="normal"
                            name="id_number"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.id_number}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.width && errors.width)}
                            fullWidth
                            helperText={touched.width && errors.width}
                            label="Šírka"
                            margin="normal"
                            name="width"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.width}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.height && errors.height)}
                            fullWidth
                            label="Výška"
                            margin="normal"
                            name="height"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.height}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.length && errors.length)}
                            fullWidth
                            helperText={touched.length && errors.length}
                            label="Hĺbka"
                            margin="normal"
                            name="length"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.length}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.tag && errors.tag)}
                            fullWidth
                            label="Tag"
                            margin="normal"
                            name="tag"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.tag}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <Box display="flex" justifyContent="flex-end" p={2}>
                      <Typography color="error" variant="body1">
                        {responseText}
                      </Typography>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Uložiť
                      </Button>
                    </Box>
                  </Card>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

BoxDetails.propTypes = {};

export default BoxDetails;
