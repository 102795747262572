import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
} from "@material-ui/core";
import BusinessIcon from "@material-ui/icons/Business";
import LockIcon from "@material-ui/icons/Lock";
import MailIcon from "@material-ui/icons/Mail";
import SmsIcon from "@material-ui/icons/Sms";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import getInitials from "src/utils/getInitials";
import NavItem from "./NavItem";
import { loggedInUserState } from "../../../services/state";

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");

  const currentUser = useRecoilValue(loggedInUserState);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    if (currentUser) {
      setUserName(`${currentUser.first_name} ${currentUser.last_name}`);
    } else {
      navigate("/login", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const itemsSuperUser = [
    {
      href: "/app/futuports",
      icon: ViewModuleIcon,
      title: "Inboxy",
    },
    {
      href: "/app/smstexts",
      icon: SmsIcon,
      title: "Texty SMS",
    },
    {
      href: "/app/companies",
      icon: BusinessIcon,
      title: "Partneri",
    },
  ];

  const itemsNormalUser = [
    {
      href: "/app/shippments",
      icon: MailIcon,
      title: "Zásielky",
    },
  ];

  const itemsUser = (currentUser && currentUser.is_superuser)
    ? [...itemsSuperUser, ...itemsNormalUser]
    : itemsNormalUser;

  /* eslint-disable */
  const itemsCommon = [
    !currentUser
      ? {
          href: "/login",
          icon: LockIcon,
          title: "Prihlásiť",
        }
      : {
          href: "/logout",
          icon: LockIcon,
          title: "Odhlásiť",
        },
  ];
  /* eslint-enable */

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          to="/app/account"
        >
          {getInitials(userName)}
        </Avatar>
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {userName}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {currentUser
            ? itemsUser.map((item) => (
                /* eslint-disable */
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                />
              ))
            : null}
        </List>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {
            /* eslint-enable */
            itemsCommon.map((item) => (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              />
            ))
          }
        </List>
      </Box>
      <Box flexGrow={1} />
      <Box p={2} m={2} bgcolor="background.dark">
        <Typography align="center" gutterBottom variant="h4">
          Potrebujete pomoc?
        </Typography>
        <Typography align="center" variant="body2">
          kontaktujte nás
        </Typography>
        <Box display="flex" justifyContent="center" mt={2}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            target="_top"
            rel="noopener noreferrer"
            href="mailto:daniel@futupilot.com"
          >
            <Typography variant="button" style={{ fontSize: "0.69rem" }}>
              Napísať email
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
