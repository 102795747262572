import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { Box, Container, makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import Page from "src/components/Page";
import Results from "./Results";
import Toolbar from "./Toolbar";
import FutuportService from "../../../services/futuport.service";
import CompanyService from "../../../services/company.service";
import { loggedInUserState } from "../../../services/state";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const FutuportListView = () => {
  const classes = useStyles();
  const [futuports, setFutuports] = useState([]);
  const [companies, setCompanies] = useState([]);
  const navigate = useNavigate();
  const currentUser = useRecoilValue(loggedInUserState);
  const [selectedFutuportIds, setSelectedFutuportIds] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState(-1);

  useEffect(() => {
    if (!currentUser) {
      navigate("/login", { replace: true });
    } else {
      FutuportService.getFutuports().then((result) => {
        setFutuports(result.data);
      });
      CompanyService.getCompanies().then((result) => {
        setCompanies(result.data);
      });
    }
  }, []);

  useEffect(() => {
    if (selectedCompanyId > 0) {
      CompanyService.getEnabledFutuports(selectedCompanyId).then((result) => {
        setSelectedFutuportIds(result.data);
      });
    } else {
      setSelectedFutuportIds([]);
    }
  }, [selectedCompanyId]);

  return (
    <Page className={classes.root} title="Inboxy">
      <Container maxWidth={false}>
        <Toolbar
          companies={companies}
          selectedCompanyId={selectedCompanyId}
          setSelectedCompanyId={setSelectedCompanyId}
        />
        <Box mt={3}>
          <Results
            futuports={futuports}
            selectedCompanyId={selectedCompanyId}
            selectedFutuportIds={selectedFutuportIds}
            setSelectedFutuportIds={setSelectedFutuportIds}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default FutuportListView;
