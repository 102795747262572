import axios from "axios";
import authHeader from "./auth-header";
import handleDates from "./date-fns";

const { REACT_APP_BACKEND_URL_BASE } = process.env;

const getShippments = (values) => {
  return axios
    .post(`${REACT_APP_BACKEND_URL_BASE}admin_shippments/`, values, {
      headers: authHeader(),
    })
    .then((response) => {
      handleDates(response.data);
      return response.data;
    });
};

const getShippment = (shippmentId) => {
  return axios
    .get(`${REACT_APP_BACKEND_URL_BASE}admin_shippment/${shippmentId}`, {
      headers: authHeader(),
    })
    .then((response) => {
      handleDates(response.data);
      return response.data;
    });
};

const getShippmentChanges = (shippmentId) => {
  return axios
    .get(
      `${REACT_APP_BACKEND_URL_BASE}admin_shippment/${shippmentId}/changes/`,
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      handleDates(response.data);
      return response;
    });
};

const getShippmentSms = (shippmentId) => {
  return axios
    .get(`${REACT_APP_BACKEND_URL_BASE}admin_shippment/${shippmentId}/sms/`, {
      headers: authHeader(),
    })
    .then((response) => {
      handleDates(response.data);
      return response;
    });
};

const updateShippment = (values) => {
  return axios
    .patch(
      `${REACT_APP_BACKEND_URL_BASE}admin_shippment/`,
      {
        id: values.id,
        addressee_phone: values.addressee_phone,
        storage_timeout: values.storage_timeout,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      handleDates(response.data);
      return response;
    });
};

export default {
  getShippments,
  getShippment,
  getShippmentSms,
  getShippmentChanges,
  updateShippment,
};
