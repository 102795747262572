import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import getInitials from "src/utils/getInitials";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({ className, shops, ...rest }) => {
  const classes = useStyles();
  const [selectedFutuportIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Názov</TableCell>
                <TableCell>Ulica, číslo</TableCell>
                <TableCell>Mesto</TableCell>
                <TableCell>PSČ</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {shops.slice(limit * page, limit * page + limit).map((shop) => (
                <TableRow
                  hover
                  key={shop.id}
                  selected={selectedFutuportIds.indexOf(shop.id) !== -1}
                >
                  <TableCell>
                    <Box alignItems="center" display="flex">
                      <Avatar className={classes.avatar}>
                        {getInitials(shop.name)}
                      </Avatar>
                      <Typography color="textPrimary" variant="body1">
                        {shop.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{shop.street_and_nr}</TableCell>
                  <TableCell>{shop.city}</TableCell>
                  <TableCell>{shop.postal_code}</TableCell>
                  <TableCell>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        navigate(`/app/company/${shop.id}`, {
                          replace: false,
                        });
                      }}
                    >
                      Zobraziť
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={shops.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Zobraziť:"
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  shops: PropTypes.array.isRequired,
};

export default Results;
