const statusToText = {
  active: "Rezervácia",
  cancelled: "Zrušená rez.",
  expired: "Expirovaná rez.",
  filled: "Vložená",
  delivered: "Vyzdvihnutá",
  torecall: "Na vrátenie",
  recalled: "Vrátená",
};

export default statusToText;
