import React, { useState, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Page from "src/components/Page";
import AuthService from "../../services/auth.service";
import CompanyService from "../../services/company.service";
import { loggedInUserState } from "../../services/state";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  chips: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
}));

// eslint-disable-next-line no-unused-vars
const CompanyDetails = ({ ...rest }) => {
  const classes = useStyles();
  const [responseText, setResponseText] = useState("");
  const navigate = useNavigate();
  const { companyId } = useParams();
  const isNew = companyId === "new";
  const currentUser = useRecoilValue(loggedInUserState);
  const setLoggedInUser = useSetRecoilState(loggedInUserState);
  const [dialogContentText, setDialogContentText] = useState("");
  const [openDialog, setOpenDialog] = React.useState(false);

  useEffect(() => {
    if (!currentUser) {
      navigate("/login", { replace: true });
    }
  }, []);

  const [currentCompany, setCurrentCompany] = useState({
    name: "",
    email: "",
    street_and_nr: "",
    city: "",
    postal_code: "",
  });

  useEffect(() => {
    if (!isNew) {
      CompanyService.getCompany(companyId).then((result) => {
        setCurrentCompany(result.data);
      });
    }
  }, []);

  const handleGenerateApiKeyClick = () => {
    CompanyService.getApiKey(companyId).then((result) => {
      setOpenDialog(true);
      setDialogContentText(`"api_key": "${result.data.api_key}"`);
    });
  };

  const handleGenerateAdminCredentialsClick = () => {
    CompanyService.getAdminCredentials(companyId).then((result) => {
      setOpenDialog(true);
      setDialogContentText(
        `"email": "${result.data.email}",\n"password": "${result.data.password}",`
      );
    });
  };

  const handleGenerateDocsCredentialsClick = () => {
    CompanyService.getDocsCredentials(companyId).then((result) => {
      setOpenDialog(true);
      setDialogContentText(
        `"login": "${result.data.login}",\n"password": "${result.data.password}",`
      );
    });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Page className={classes.root} title="Detail">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Formik
              initialValues={{
                name: currentCompany.name,
                email: currentCompany.email,
                streetAndNr: currentCompany.street_and_nr,
                city: currentCompany.city,
                postalCode: currentCompany.postal_code,
              }}
              enableReinitialize
              validationSchema={Yup.object().shape({
                name: Yup.string()
                  .min(3, "Názov je povinný")
                  .max(255)
                  .required("Názov je povinný"),
                email: Yup.string()
                  .email("Zadajte emailovú adresu")
                  .required("Zadajte emailovú adresu"),
                streetAndNr: Yup.string()
                  .min(3, "Zadajte ulicu a číslo")
                  .max(255)
                  .required("Zadajte ulicu a číslo"),
                city: Yup.string()
                  .min(3, "Zadajte mesto alebo obec")
                  .max(255)
                  .required("Zadajte mesto alebo obec"),
                postalCode: Yup.string()
                  .min(4, "zadajte platné PSČ")
                  .max(255)
                  .required("Zadajte PSČ"),
              })}
              onSubmit={(values, actions) => {
                if (isNew) {
                  CompanyService.createCompany(values)
                    .then((result) => {
                      actions.setSubmitting(false);
                      navigate(`/app/company/${result.data.id}`, {
                        replace: true,
                      });
                    })
                    .catch((err) => {
                      actions.setSubmitting(false);
                      console.log(err);
                      if (err.response.status === 401) {
                        AuthService.logout();
                        setLoggedInUser(null);
                      }
                      try {
                        setResponseText(err.response.statusText);
                      } catch {
                        // continue regardless of error
                      }
                    });
                } else {
                  CompanyService.updateCompany({ id: companyId, ...values })
                    .then(() => {
                      actions.setSubmitting(false);
                    })
                    .catch((err) => {
                      actions.setSubmitting(false);
                      if (err.response.status === 401) {
                        AuthService.logout();
                        setLoggedInUser(null);
                        navigate("/login", { replace: true });
                      }
                      try {
                        setResponseText(err.response.statusText);
                      } catch {
                        // continue regardless of error
                      }
                    });
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Card>
                    <CardHeader title="Partner" />
                    <Divider />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={4} xs={12}>
                          <TextField
                            error={Boolean(touched.name && errors.name)}
                            fullWidth
                            label="Názov"
                            margin="normal"
                            name="name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.name}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <TextField
                            error={Boolean(touched.email && errors.email)}
                            fullWidth
                            label="Email"
                            margin="normal"
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <TextField
                            error={Boolean(
                              touched.streetAndNr && errors.streetAndNr
                            )}
                            fullWidth
                            helperText={
                              touched.streetAndNr && errors.streetAndNr
                            }
                            label="Ulica a číslo"
                            margin="normal"
                            name="streetAndNr"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.streetAndNr}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.city && errors.city)}
                            fullWidth
                            helperText={touched.city && errors.city}
                            label="Mesto"
                            margin="normal"
                            name="city"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.city}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(
                              touched.postalCode && errors.postalCode
                            )}
                            fullWidth
                            helperText={touched.postalCode && errors.postalCode}
                            label="PSČ"
                            margin="normal"
                            name="postalCode"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.postalCode}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <Box display="flex" justifyContent="flex-end" p={2}>
                      <Typography color="error" variant="body1">
                        {responseText}
                      </Typography>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Uložiť
                      </Button>
                    </Box>
                  </Card>
                </form>
              )}
            </Formik>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader subheader="Prístup k API a dokumentácii" />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Box p={2}>
                    <Typography variant="body1">
                      &quot;VYGENEROVAŤ NOVÝ API KĽÚČ&quot; - vytvoríte nový API
                      kľúč a starý sa stane neplatným.
                    </Typography>
                    <Typography variant="body1">
                      &quot;VYGENEROVAŤ DOCS PRÍSTUP&quot; - vytvoríte nové meno
                      a heslo na prístup k https://stage.futuport.com/docs a
                      staré prístupové údaje sa zneplatnia.
                    </Typography>
                    <Typography variant="body1">
                      &quot;VYGENEROVAŤ ADMIN PRÍSTUP&quot; - vytvoríte nové
                      meno heslo na prístup k https://admin.futuport.com/ a
                      staré prístupové údaje sa zneplatnia.
                    </Typography>
                  </Box>
                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    handleGenerateApiKeyClick();
                  }}
                >
                  Vygenerovať nový API kľúč
                </Button>
                <Box px={1}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      handleGenerateDocsCredentialsClick();
                    }}
                  >
                    Vygenerovať docs prístup
                  </Button>
                </Box>
                <Box px={1}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      handleGenerateAdminCredentialsClick();
                    }}
                  >
                    Vygenerovať admin prístup
                  </Button>
                </Box>
                <Dialog
                  open={openDialog}
                  onClose={handleCloseDialog}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    Prístupové údaje
                  </DialogTitle>
                  <DialogContent>
                    <pre>{dialogContentText}</pre>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleCloseDialog}
                      color="primary"
                      autoFocus
                    >
                      Zavrieť
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

CompanyDetails.propTypes = {};

export default CompanyDetails;
