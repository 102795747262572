import React, { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.service";
import { loggedInUserState } from "../../services/state";

const Logout = () => {
  const navigate = useNavigate();
  const setLoggedInUser = useSetRecoilState(loggedInUserState);

  useEffect(() => {
    AuthService.logout();
    setLoggedInUser(null);
    navigate("/login", { replace: true });
  });
  return <p />;
};

export default Logout;
