import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({
  className,
  shippments,
  statusToText,
  currentUser,
  ...rest
}) => {
  const classes = useStyles();
  const [selectedSmstextIds] = useState([]);
  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Stav</TableCell>
                <TableCell>Inbox</TableCell>
                <TableCell>Schránka</TableCell>
                {(currentUser && currentUser.is_superuser) ? <TableCell>Partner</TableCell> : ""}
                <TableCell>Podacie č.</TableCell>
                <TableCell>Tel. č.</TableCell>
                <TableCell>Lehota</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {shippments
                .slice(limit * page, limit * page + limit)
                .map((shippment) => (
                  <TableRow
                    hover
                    key={shippment.id}
                    selected={selectedSmstextIds.indexOf(shippment.id) !== -1}
                  >
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <Typography color="textPrimary" variant="body1">
                          {shippment.id}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <Typography color="textPrimary" variant="body1">
                          {statusToText[shippment.status]}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <Typography color="textPrimary" variant="body1">
                          {shippment.inbox_name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <Typography color="textPrimary" variant="body1">
                          {shippment.box_number}
                        </Typography>
                      </Box>
                    </TableCell>
                    {(currentUser && currentUser.is_superuser) ? (
                      <TableCell>
                        <Box alignItems="center" display="flex">
                          <Typography color="textPrimary" variant="body1">
                            {shippment.partner_name}
                          </Typography>
                        </Box>
                      </TableCell>
                    ) : (
                      ""
                    )}
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <Typography color="textPrimary" variant="body1">
                          {shippment.code}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <Typography color="textPrimary" variant="body1">
                          {shippment.addressee_phone}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <Typography color="textPrimary" variant="body1">
                          {shippment.storage_timeout
                            ? shippment.storage_timeout.toLocaleString("sk-SK")
                            : ""}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box display="flex" justifyContent="flex-start">
                        <Box pl={1}>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              navigate(`/app/shippment/${shippment.id}`, {
                                replace: false,
                              });
                            }}
                          >
                            Detail
                          </Button>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={shippments.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[25, 50, 100]}
        labelRowsPerPage="Zobraziť:"
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  shippments: PropTypes.array.isRequired,
  statusToText: PropTypes.object,
  currentUser: PropTypes.object,
};

export default Results;
