import React, { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import AuthService from "src/services/auth.service";
import { loggedInUserState } from "../../../services/state";

const useStyles = makeStyles({
  root: {},
});

const Password = ({ className, ...rest }) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    password: "",
    confirm: "",
  });
  const [responseText, setResponseText] = useState("");
  const navigate = useNavigate();
  const currentUser = useRecoilValue(loggedInUserState);
  const setLoggedInUser = useSetRecoilState(loggedInUserState);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <form className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader subheader="Update password" title="Password" />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Password"
            margin="normal"
            name="password"
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Confirm password"
            margin="normal"
            name="confirm"
            onChange={handleChange}
            type="password"
            value={values.confirm}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Box mx={2}>
            <Typography color="error" variant="body1">
              {responseText}
            </Typography>
          </Box>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              AuthService.updateUserPassword({
                password: values.password,
                ...currentUser,
              })
                .then((response) => {
                  try {
                    setResponseText(response.statusText);
                  } catch {
                    // continue regardless of error
                  }
                })
                .catch((err) => {
                  if (err.response.status === 401) {
                    AuthService.logout();
                    setLoggedInUser(null);
                    navigate("/login", { replace: true });
                  }
                  try {
                    setResponseText(err.response.data.detail);
                  } catch {
                    // continue regardless of error
                  }
                });
            }}
          >
            Update
          </Button>
        </Box>
      </Card>
    </form>
  );
};

Password.propTypes = {
  className: PropTypes.string,
};

export default Password;
