import React, { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { Box, Button, Container, Grid, makeStyles } from "@material-ui/core";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Page from "src/components/Page";
import { loggedInUserState } from "../../../services/state";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  productCard: {
    height: "100%",
  },
  button: {
    minHeight: "50px",
    minWidth: "300px",
    margin: "10px 0",
  },
}));

const Home = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const currentUser = useRecoilValue(loggedInUserState);

  useEffect(() => {
    if (!currentUser) {
      navigate("/login", { replace: true });
    }
  }, []);
  return (
    <Page className={classes.root} title="Futuport Admin | Home">
      <Container maxWidth={false}>
        <Box mt={3}>
          <Grid container spacing={3}>
            <Button
              className={classes.button}
              variant="contained"
              fullWidth
              color="primary"
              component={RouterLink}
              to="/app/scanqr"
            >
              Snímať QR kód
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              fullWidth
              color="secondary"
              component={RouterLink}
              to="/app/myqr"
            >
              Odovzdať moje údaje
            </Button>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default Home;
