import axios from "axios";
import authHeader from "./auth-header";

const { REACT_APP_BACKEND_URL_BASE } = process.env;
const API_AUTH_URL = `${REACT_APP_BACKEND_URL_BASE}auth/`;
const API_USERS_URL = `${REACT_APP_BACKEND_URL_BASE}users/`;

const register = (values) => {
  return axios.post(`${API_AUTH_URL}register`, {
    email: values.email,
    password: values.password,
    first_name: values.firstName,
    last_name: values.lastName,
    phone: values.phone,
    street_and_nr: values.streetAndNr,
    city: values.city,
    postal_code: values.postalCode,
  });
};

const login = (username, password) => {
  return axios
    .post(
      `${API_AUTH_URL}jwt/login`,
      `username=${username}&password=${password}`
    )
    .then((response) => {
      if (response.data.access_token) {
        localStorage.setItem("access_token", response.data.access_token);
      }
    });
};

const logout = () => {
  localStorage.removeItem("access_token");
};

const loadCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user_v2"));
};

const getUserProfile = () => {
  return axios
    .get(`${API_USERS_URL}me`, {
      headers: authHeader(),
    })
    .then((response) => {
      localStorage.setItem("user_v2", JSON.stringify(response.data));
      return response;
    });
};

const updateUserProfile = (values) => {
  return axios.patch(
    `${API_USERS_URL}me`,
    {
      first_name: values.firstName,
      last_name: values.lastName,
    },
    {
      headers: authHeader(),
    }
  );
};

const updateUserPassword = (values) => {
  return axios.patch(`${API_USERS_URL}me`, values, {
    headers: authHeader(),
  });
};

const downloadLeaflet = (shopid) => {
  return axios({
    url: `${REACT_APP_BACKEND_URL_BASE}shopleaflet/${shopid}`,
    method: "GET",
    headers: authHeader(),
    responseType: "blob", // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Zapisma-QR-${shopid}.pdf`); // or any other extension
    document.body.appendChild(link);
    link.click();
  });
};

export default {
  register,
  login,
  logout,
  getUserProfile,
  loadCurrentUser,
  updateUserProfile,
  updateUserPassword,
  downloadLeaflet,
};
